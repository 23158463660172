import { Box, Button, Card, CardActions, CardContent, CardMedia, IconButton, TextField, Typography } from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import imgr from '../../../assets/fill.png'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CheckIcon from '@mui/icons-material/Check';
import notImage from '../../../assets/product-default.png'
import { addProductCar } from '../../../redux/slices/marketPLace/marketPlaceSlice';
import { Contador } from './Contador';
import PopUp from '../../../components/PopUp';
import QuoteFlash from '../../../components/PopUp/components/Flash/QuoteFlash';

export const CardProductMovil = ({ item }) => {


    const dispatch = useDispatch()
    const { productsCar } = useSelector((state) => state.cart)
    const [showModal, setShowModal] = useState(false)

    const itemExist = (id) => {

        if (productsCar.some((item) => item.id === id)) {
            return true
        } else {
            return false
        }

    }

    const addToCart = (item) => {


        dispatch(addProductCar(item))
    }

    return (
        <Card sx={{
            marginTop: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginLeft: { xs: 0, md: 2 },
            marginBottom: 2,
            minHeight: 180 // Establece una altura mínima para el Card

        }}

        >
            <PopUp size={'large'} setShowModal={setShowModal} showModal={showModal}>
                <QuoteFlash
                    item={item}
                    setShowModal={setShowModal}
                />
            </PopUp>
            <Box sx={{ display: 'flex' }}  >
                <CardMedia
                    component="img"

                    sx={{
                        height: 120, // Establece una altura fija para la imagen
                        objectFit: 'cover', // Asegura que la imagen se ajuste sin distorsionarse
                        width: 150
                    }}
                    image={item.image === null ? notImage : item.image}
                    alt="Live from space album cover"
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <CardContent >
                        <Typography marginBottom={0.5} sx={{ fontSize: { xs: '0.8rem', md: '0.8rem' } }} color={'#72BFBA'} component="div">
                            {`${item.supplier}`}
                        </Typography>
                        <Typography sx={{
                            color: '#5c5c5c',
                            fontWeight: 'bold',
                            marginBottom: 0.5,
                            fontSize: { xs: '1.1rem', md: '1.1rem' },
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                        }}>
                            {`${item.name}`}
                        </Typography>
                        {/* {
                            item.price !== null ?
                                <Typography sx={{ fontSize: { xs: '1.2rem', md: '1.3rem' } }} fontWeight={'bold'} component="div" >
                                    {`$${item.price} x ${item.measurementUnit}`}
                                </Typography> : null


                        } */}

                    </CardContent>
                </Box>
            </Box>
            {/* <Box ml={3} >
                <Contador
                    item={item}
                />
            </Box> */}

            {/* <Box borderRadius={50} marginTop={2} display={'flex'} flexDirection={'row'} alignItems={'center'} >
                <IconButton aria-label="fingerprint"  >
                    <RemoveIcon color='red' />
                </IconButton>
                <TextField
                    value={item.cantidad}
                    inputProps={{
                        style: {
                            fontSize: 14,
                            textAlign: 'center',
                            height: 32,
                            width: 32,
                            padding: '0',
                            fontWeight: 'bold',
                            borderRadius: '50px'
                        },
                    }}

                    id="demo-helper-text-aligned-no-helper"

                />
                <IconButton aria-label="fingerprint" backgroundColor='red' >
                    <AddIcon color='red' />
                </IconButton>
            </Box> */}

            <CardActions  >
                <Button onClick={() => { setShowModal(true) }} sx={{ "&:hover": { backgroundColor: '#57575b' }, backgroundColor: '#32344B', borderRadius: 10, height: 36 }} fullWidth variant='contained'  > <img src={imgr} alt="" /> Cotizar ahora </Button>
                <Button disabled={itemExist(item.id) ? true : false} onClick={() => addToCart(item)} sx={{ "&:hover": { backgroundColor: '#71b17c' }, backgroundColor: itemExist(item.id) ? '#fcfcfc' : '#49A35B', borderRadius: 10, marginLeft: 2 }} fullWidth variant='contained'  >{itemExist(item.id) ? <CheckIcon color='success' /> : ' Agregar'}</Button>
            </CardActions>


        </Card>
    )
}
