/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import DatePicker, { DateObject, getAllDatesInRange } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import { useDispatch, useSelector } from 'react-redux';
import Loading from 'components/Loading';
import makeAnimated from "react-select/animated";
import Select from 'react-select';
import { components } from 'react-select';
import { Option } from '../../components/Option/Option';
import styles from './reports.module.scss'
import { clearR, setReport, setSuppliersDocs, setSuppliersR } from '../../redux/slices/thunks';
import ReportsTable from './ReportsTable';
import useStore from 'store';
import { Grid, Typography } from '@mui/material';
import MySelect from '../../components/Select/MySElect';
import { DatePIckerMulti } from '../../components/DatePickerCAdo/DatePIckerMulti';
import Banner from '../../components/Banner/Banner';
import BannerEsta from '../../components/Banner/BannerEsta';
import { HomeLayouts } from '../../components/layouts/HomeLayouts';
import { clearParamsO } from '../../redux/slices/paramsSlice';


//

const ReportParent = ({ restaurantr }) => {

    const [suppliers, setsuppliers] = useState({ optionSelected: [] })
    const [suppliersL, setsuppliersL] = useState({ optionSelected: null })
    const { restaurant, subscription, token } = useSelector((state) => state.auth)
    const { supplierListAll, isLoading } = useSelector((state) => state.suppliersReducer)
    const { reports, isLoadingR, error } = useSelector((state) => state.reportsReducer)
    const dispatch = useDispatch()
    // const subscription = useStore((state) => state.subscription);
    const store = useStore((state) => state);
    const animatedComponents = makeAnimated();
    const [dates, setDates] = useState({
        fromDate: '',
        toDate: ''
    });

    console.log(suppliersL)
    useEffect(() => {
        dispatch(clearParamsO())
        dispatch(clearR())
        setsuppliers([])
        setsuppliersL({
            optionSelected: null
        });
        setDates({
            fromDate: '',
            toDate: ''
        })
        dispatch(setSuppliersDocs(restaurant, token))


    }, [restaurant])


    useEffect(() => {


        enviarInfoirm()


    }, [dates, suppliers])

    const MultiValue = props => (
        <components.MultiValue {...props}>
            <span>{props.data.label}</span>
        </components.MultiValue>
    );
    const enviarInfoirm = () => {

        if (!dates.fromDate || !dates.toDate || suppliers?.optionSelected < 1) {

            return dispatch(clearR())

        } else {

            dispatch(setReport(restaurant, dates.fromDate, dates.toDate, suppliers.optionSelected, token))
        }

    }

    const handleChange = selected => {
        const suppliersAux = selected.map(val => val.value);
        setsuppliers({
            optionSelected: suppliersAux
        });
        setsuppliersL({
            optionSelected: selected
        });
    };

    if (subscription?.status === 'cancelada') {

        return (

            <HomeLayouts>
                <Banner />
            </HomeLayouts>



        )

    }


    return (
        <>

            <HomeLayouts>
                {
                    isLoading ? <Loading /> :


                        <div className={styles.content}>
                            <div className={styles.header}>
                                <div>
                                    <h1 className={styles.title}>Reportes</h1>
                                </div>
                            </div>

                            <Grid container display={'flex'} alignItems='center' spacing={3} justifyContent={'space-evenly'} >

                                <Grid item md={5} xs={12} display='flex' flexDirection={'column'} >

                                    <label > Seleccionar proveedor: </label>
                                    {
                                        supplierListAll && supplierListAll.length ?
                                            <MySelect
                                                options={supplierListAll}
                                                isMulti
                                                className="basic-multi-select"
                                                hideSelectedOptions={false}
                                                components={{ Option, MultiValue, animatedComponents }}
                                                onChange={handleChange}
                                                allowSelectAll={true}
                                                placeholder='Selecciona los proveedores'
                                                isSearchable
                                                value={suppliersL.optionSelected}

                                            />
                                            : null
                                    }


                                </Grid>
                                <Grid item md={4} xs={12} display='flex' flexDirection={'column'} justifyContent='center' >
                                    <label> Seleccionar rango de fechas: </label>

                                    <DatePIckerMulti
                                        setDAtes={setDates}

                                    />
                                </Grid>
                            </Grid>
                        </div>
                }

                <div className={styles.content}>

                    {
                        reports.length > 0 ? <ReportsTable
                            data={reports}
                            loading={isLoadingR}
                        /> :

                            <Grid border={0.3} borderRadius={3} borderColor='#EEEEEE' display='flex' height={260} alignItems={'center'} justifyContent='center'  >
                                <Typography color={'#DDDDDD'} >No hay datos </Typography>
                            </Grid>
                    }
                </div>
            </HomeLayouts>
        </>

    );
};

export default ReportParent;
