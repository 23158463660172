import React, { useState } from 'react'

import styles from '../../Reports/reports.module.scss'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'


export const BtnResume = () => {

    const { productsCar } = useSelector((state) => state.cart)
    const navigate = useNavigate();
    // 


    return (
        <button
            disabled={false}
            className={styles.button}
            onClick={() => navigate('/marketplace/carrito')}
        >
            Resumen {productsCar === undefined ? 0 : productsCar.length}
        </button>
    )
}
