//'https://api-stg.cado.ai/quotes/user/{userId}/restaurant/{restaurantId}?userId=129&restaurantId=47'

import axios from "axios";



export const quoteByRestaurant = async (token, userId, restaurantId )=>{

    

    const response = await axios.get( 'https://api-stg.cado.ai/quotes/user/{userId}/restaurant/{restaurantId}?userId=129&restaurantId=47', //`${process.env.API_URL}/quotes/user/{userId}/restaurant/{restaurantId}?${userId}&${restaurantId}`,
    {
     withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
      });

      if (response.status === 200) {
            return { ok:true,  data: response.data}
      }else{
        return { ok:false,  data:[] }
      }

  }