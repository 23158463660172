import { persistReducer } from "redux-persist";

import { documentsSlice } from "./slices/documentsSlice";
import { orderSlice } from "./slices/orderSlice";
import { productsSlice } from "./slices/productsSlice";
import { reportSlice } from "./slices/reportsSlice";
import { restaurantsSlice } from "./slices/restaurantSlice";
import { suppliersSlice } from "./slices/suppliersSlice";
import storage from 'redux-persist/lib/storage'
import { paramsSlice } from "./slices/paramsSlice";
import { preciosSlice } from "./slices/preciosSlice";
import { authSlice } from "./slices/authSlice/authSlice";
import { authSliceV2 } from "./slices/authSlice/authSliceV2";
import { marketSlice } from "./slices/marketPLace/marketPlaceSlice";
const { configureStore, combineReducers } = require("@reduxjs/toolkit");


const persistConfig = {

  key: 'root',
  storage,
  whitelist:['auth', 'cart']
 
}

const reducer = combineReducers({


      auth:authSliceV2.reducer,
      restaurant :restaurantsSlice.reducer,
        // // restaurantsCReducer:  restaurantsSlice.reducer ,
          suppliersReducer:suppliersSlice.reducer,
          reportsReducer:reportSlice.reducer,
          orderReducer:orderSlice.reducer,
          productsReducer:productsSlice.reducer,
          documents:documentsSlice.reducer,
        // // auth: authSlice.reducer,
          params : paramsSlice.reducer,
          cart: marketSlice.reducer,
          pricesPrin:preciosSlice.reducer

})

const persistedReducer = persistReducer( persistConfig, reducer )

const storeS = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),

})


export default storeS;