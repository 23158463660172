import { Box, Card, CardContent, CardHeader, CardMedia, IconButton, Typography } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear';
import React from 'react'
import { Contador } from './Contador';
import { useDispatch } from 'react-redux';
import notImage from '../../../assets/product-default.png'
//import notImage from '../../assets/product-default.png'
import { addCant, removeProductCar } from '../../../redux/slices/marketPLace/marketPlaceSlice';

export const CardResumeMarket = ({ item }) => {
    const dispatch = useDispatch()
    const removeProduct = (item) => {

        dispatch(removeProductCar(item))

    }

    const add = (id, nuevaCantidad) => {


        dispatch(addCant({ id, cantidad: nuevaCantidad }))

    }
    const dism = (id, nuevaCantidad) => {


        dispatch(addCant({ id, cantidad: nuevaCantidad }))

    }


    return (
        <Card sx={{ marginTop: 1, padding: 1 }} >

            <Box display={'flex'} flexDirection='row' alignItems={'center'} justifyContent={'space-between'} >
                <Box display={'flex'} flexDirection={'row'} alignItems={'center'} >
                    <CardMedia
                        component="img"
                        sx={{ width: 90 }}
                        image={item.image === null ? notImage : item.image}
                        alt={item.name}
                    />
                    <Box ml={1} >
                        <Typography
                            sx={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                display: "-webkit-box",
                                WebkitLineClamp: "2",
                                WebkitBoxOrient: "vertical",
                            }}>
                            {item.name} </Typography>
                        <Typography noWrap fontSize={14} color='#49A35B'> {item.supplier} </Typography>
                        {/* {item.price !== null ?
                            <Typography noWrap fontSize={14} >  {`$${item.price} x ${item.measurementUnit}`} </Typography> : null} */}

                    </Box>
                </Box>
                <IconButton onClick={() => removeProduct(item)} aria-label="settings">
                    <ClearIcon />
                </IconButton>
            </Box>
            {/* <Box marginTop={3} marginLeft={2} >
                <Contador dism={dism} add={add} item={item} />
            </Box> */}
        </Card>
    )
}
