import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { shouldHideNavbar } from '../../utils/routes';
import links from './links';

import logo from 'assets/logo_name.png';
import hamburger from 'assets/hamburger.svg';
import closeIcon from 'assets/closeIcon.svg';
import PopUp from '../../components/PopUp';
import AddSupplier from 'components/PopUp/components/AddSupplier';
import ProfileInfo from '../PopUp/components/ProfileInfo';
import Select from '../Select';

import CTA from '../CTA';

import styles from './hamburgerMenu.module.scss';

import useStore from 'store';
import { useDispatch, useSelector } from 'react-redux';

import { linkADmin, linkEstandarH, linksAH, linksSH } from './linksA';
import { LinkC } from './Link';
import { LinkMenu } from './LinkMenu';
import { Box, Typography } from '@mui/material';
import { linkEStandar } from '../Sidebar/linksF';
import { setRestaurantV2 } from '../../redux/slices/authSlice/authSliceV2';
import { BtnResume } from '../../views/Marketplace/components/BtnResume';
//import { clearParamsO } from '../../redux/slices/paramsSlice';



const HamburgerMenu = ({ restaurantLista, setRerender, restaurantId }) => {



  const { user, restaurant, subscription, restaurantsSelect } = useSelector((state) => state.auth)


  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const { orders_allowed } = useSelector((state) => state.orderReducer)
  const [isOpen, setIsOpen] = useState(false);
  const [clickedCard, setClickedCard] = useState(null);
  const [showModalUser, setShowModalUser] = useState(false);


  const { pathname } = useLocation();

  console.log(pathname)

  if (shouldHideNavbar(pathname)) return null;


  return (
    <>
      <Navigation
        restaurant={restaurant}
        subscription={subscription}
        restaurantList={restaurantsSelect}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        // handleLocation={handleLocation}
        restaurantId={restaurantId}
      />
      <PopUp setShowModal={setShowModal} showModal={showModal}>
        <AddSupplier setRerender={setRerender} setShowModal={setShowModal} />
      </PopUp>
      <PopUp setShowModal={setShowModalUser} showModal={showModalUser}>
        <ProfileInfo
          clickedCard={clickedCard}
          user={user}
          setShowModal={setShowModal}
          local={[]}
        />
      </PopUp>
      <div className={styles.header}>
        <div
          onClick={() => {
            setIsOpen(true);
          }}
          className={styles.clickable}
        >
          <img src={hamburger} className={styles.hamburger} alt="hamburger" />
          <img src={logo} className={styles.logo} alt="cado logo" />
        </div>
        {(pathname === '/ordenes' || pathname === '/') && (
          <div className={styles.ctaWrapper}>
            <Box  >
              {subscription?.status !== "cancelada" && user.roles[0].name !== 'Staff' && (
                <CTA
                  isDisabled={orders_allowed?.status === true && orders_allowed.number <= 0}
                  text="Nueva Orden"
                  size="small"
                  onClick={() => {

                    localStorage.setItem('step', 1);
                    localStorage.setItem('state', JSON.stringify({}));
                    navigate('/ordenes/nueva');
                  }}
                />
              )}
            </Box>

          </div>
        )}
        {pathname === '/proveedores' && (
          <div className={styles.ctaWrapper}>
            {subscription && subscription.status !== "cancelada" && (
              <CTA
                text="Añadir Proveedor"
                size="small"
                onClick={() => {
                  setShowModal(true);
                }}
              />
            )
            }

          </div>
        )}
        {pathname === '/users' && (
          <div className={styles.ctaWrapper}>
            <CTA
              text="Añadir Usuario"
              size="small"
              onClick={() => {
                setClickedCard('usersByOwner');
                setShowModalUser(true);
              }}
            />
          </div>
        )}
        {pathname === '/marketplace' && (
          <div className={styles.ctaWrapper}>
            <BtnResume
              text="Resumen"
              size="small"
              onClick={() => navigate('/marketplace/carrito')}
            />
          </div>
        )}
        {pathname === 'categoria/:category' && (
          <div className={styles.ctaWrapper}>
            <BtnResume
              text="Resumen"
              size="small"
              onClick={() => navigate('/marketplace/carrito')}
            />
          </div>
        )}
      </div>
    </>
  );
};

// HamburgerMenu.propTypes = {
//   setRerender: PropTypes.func.isRequired,
//   handleLocation: PropTypes.func.isRequired,
//   restaurantId: PropTypes.number,
// };

const Navigation = ({ subscription, restaurantList, isOpen, setIsOpen }) => {
  const { user, restaurant } = useSelector((state) => state.auth)
  const locationsStore = useStore((state) => state.locations);
  const [restaurantOptions, setRestaurantOptions] = useState([]);
  const dispatch = useDispatch()


  const handleLocation = ({ target }) => {


    dispatch(setRestaurantV2(target.value))

  };





  return (

    <div className={`${styles.wrapper} ${isOpen ? styles.open : ''}`}>
      <div className={styles.container}>
        <img
          src={closeIcon}
          className={styles.closeIcon}
          onClick={() => setIsOpen(false)}
        />
        {restaurantList.length > 1 && <p>Seleccione el comercio:</p>}
        {restaurantList.length > 1 && (
          <Select
            onChange={handleLocation}
            options={restaurantList}
            restaurant={restaurant ? restaurant : ''}
          />
        )}
        {
          user?.roles[0]?.name === 'Staff' ?
            linksSH.map((link, i) => {
              return (
                <Link
                  to={link.to}
                  key={`${i}_${link.to}`}
                  className={styles.link}
                  onClick={() => setIsOpen(false)}

                >
                  <img src={link.icon} className={styles.icons} />
                  {link.name}
                </Link>
              );
            })
            :

            !subscription || subscription.plan.abreviacion != "gold" ?

              linkEstandarH.map((link, i) => link.list.length === 0 ?
                (

                  <LinkC
                    restaurant={restaurant}
                    key={i}
                    to={link.to}
                    name={link.name}
                    icon={link.icon}
                  />

                ) :
                (
                  <LinkMenu
                    restaurant={restaurant}
                    key={i}
                    icon={link.icon}
                    links={link.list}
                    name={link.name}
                  >
                  </LinkMenu>
                )
              ) :

              linkADmin.map((link, i) => link.list.length === 0 ?

                (
                  <LinkC
                    key={i}
                    to={link.to}
                    name={link.name}
                    icon={link.icon}
                  />
                ) :
                (
                  <LinkMenu
                    key={i}
                    icon={link.icon}
                    links={link.list}
                    name={link.name}
                  >
                  </LinkMenu>
                )
              )
        }
      </div>
    </div>
  );
};

// Navigation.propTypes = {
//   isOpen: PropTypes.bool.isRequired,
//   setIsOpen: PropTypes.func.isRequired,
//   handleLocation: PropTypes.func.isRequired,
//   restaurantId: PropTypes.number,
// };

export default HamburgerMenu;
