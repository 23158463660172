import routes from 'router/routeList';
import chartBar from 'assets/chartBar.svg';
import packageIcon from 'assets/package.svg';
import receiptIcon from 'assets/receipt.svg';
import userIcon from 'assets/user.svg';
import report from 'assets/report.svg';
import logout from 'assets/logout.svg';
import products from 'assets/products.svg';
 
 

export const linksA = [
    { name: 'Resumen', to: routes.home, icon: chartBar },
    { name: 'Órdenes', to: routes.orders, icon: receiptIcon },
    { name: 'Reportes', to: routes.report, icon: report },
    { name: 'Productos', to: routes.products, icon: products },
    { name: 'Documentos', to: routes.documents, icon: products },
    { name: 'Proveedores', to: routes.suppliers, icon: packageIcon },
    { name: 'Perfil', to: routes.profile, icon: userIcon },
    { name: 'Cerrar sesión', to: routes.logout, icon: logout },

]

export const linksS = [
    { name: 'Resumen', to: routes.home, icon: chartBar },
    { name: 'Órdenes', to: routes.orders, icon: receiptIcon },
    { name: 'Perfil', to: routes.profile, icon: userIcon },
    { name: 'Cerrar sesión', to: routes.logout, icon: logout },
]
 
export const linkADmin = [
    {
        name:'Marketplace', 
        to: routes.marketplace, 
        icon: chartBar,
        list:[]
    },
        {
            name:'Resumen', 
            to: routes.home, 
            icon: chartBar,
            list:[]
        },
        {
            name: 'Mi Local', 
            to: routes.orders,
            icon: receiptIcon,
            list:[
                {
                   name:'Ordenes',
                   to: routes.orders,
                },
                {
                    name: 'Proveedores', 
                    to: routes.suppliers,
                 },
                 { 
                    name: 'Documentos', 
                    to: routes.documents, icon: products
                    
                },
                { 
                    name: 'Coti', 
                    to: routes.documents, icon: products
                    
                }     
            ]
        },
        {
            name: 'Reportes', 
            to: routes.report,
            icon: report,
            list:[   
                // {
                //     name:'Analítica',
                //     to: routes.chart,
                //  }, 
                
         {
            name:'Productos',
            to: routes.products,
         },
         {
             name: 'Compras', 
             to: routes.report,
          },
             
         
                ]
         },
         { 
            name: 'Perfil', 
            to: routes.profile, 
            icon: userIcon,
            list:[]
        },
         { 
            name: 'Cerrar sesión', 
            to: routes.logout, 
            icon: logout ,
            list:[]
         },

  ]



  export const linkEStandar = [
    {
        name:'Marketplace', 
        to: routes.marketplace, 
        icon: chartBar,
        list:[]
    },
    {
        name:'Resumen', 
        to: routes.home, 
        icon: chartBar,
        list:[]
    },
    {
        name: 'Mi Local', 
        to: routes.orders,
        icon: receiptIcon,
        list:[
            {
               name:'Ordenes',
               to: routes.orders,
            },
            {
                name: 'Proveedores', 
                to: routes.suppliers,
             },
        ]
    },
    {
        name: 'Reportes', 
        to: routes.report,
        icon: report,
        list:[    
        {
         name: 'Compras', 
         to: routes.report,
      },
         
     
            ]
     },
     { 
        name: 'Perfil', 
        to: routes.profile, 
        icon: userIcon,
        list:[]
    },
     { 
        name: 'Cerrar sesión', 
        to: routes.logout, 
        icon: logout ,
        list:[]
     },

]