import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import styles from '../Reports/reports.module.scss'
import imgr from '../../assets/fill.png'
import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, IconButton, Link, TextField, Typography } from '@mui/material'
import CTA from '../../components/CTA'
import AddIcon from '@mui/icons-material/Add';
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css';
import { object } from 'prop-types'
import { products } from './data'
import SearchBarMarket from '../../components/SearchBar/SearchBarMarket'
import RemoveIcon from '@mui/icons-material/Remove';
import { CardProductMovil } from './components/CardProductMovil'
import { CarrouselComp } from './components/CarrouselComp'
import { BtnResume } from './components/BtnResume'
import { useDispatch, useSelector } from 'react-redux'
import { addCant } from '../../redux/slices/marketPLace/marketPlaceSlice'
import { getProductsMarketPlace } from '../../api/marcketPlace'
import Loading from '../../components/Loading'






export const Marketplace = () => {
    const { token } = useSelector((state) => state.auth)
    const [query, setQuery] = useState('');
    const [categorias, setCategorias] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingCat, setloadingCat] = useState(false);
    const [categoriasBna, setCategoriasBan] = useState(null);
    const [filteredProducts, setFilteredProducts] = useState(null);
    const [productos, setproductos] = useState(null);
    const [cantidad, setcantidad] = useState(1)
    const [catSelect, setCatSeklect] = useState('Todos')

    useEffect(() => {

        setData()

    }, [])


    const dism = (id, nuevaCantidad) => {


        setproductos(prevProductos =>
            prevProductos.map(producto =>
                producto.id === id ? { ...producto, cantidad: nuevaCantidad } : producto
            )
        );

    }



    const add = (id, nuevaCantidad) => {


        setproductos(prevProductos =>
            prevProductos.map(producto =>
                producto.id === id ? { ...producto, cantidad: nuevaCantidad } : producto
            )
        );

    }






    const setData = async () => {
        setLoading(true)
        const pro = await getProductsMarketPlace(token);
        const categoriasDinamicas = {};
        const productFil = []
        const cat = []

        if (pro.ok) {
            pro.data.forEach(product => {
                if (!categoriasDinamicas[product.category]) {
                    categoriasDinamicas[product.category] = [];
                }
                categoriasDinamicas[product.category].push({ ...product, cantidad: 1 });
                productFil.push({ ...product, cantidad: 1 })
                cat.push(product.category)
            });
            const kk = Object.keys(categoriasDinamicas).map((categoria, index) => {
                return categoria
            }

            )
            setCategoriasBan(kk)
            setproductos(productFil)
            setCategorias(categoriasDinamicas);
            setLoading(false)
        } else {

            setCategoriasBan(null)
            setproductos(null)
            setCategorias(null);
            setLoading(false)

        }
        // products.forEach(product => {
        //     if (!categoriasDinamicas[product.categori]) {
        //         categoriasDinamicas[product.categori] = [];
        //     }
        //     categoriasDinamicas[product.categori].push({ ...product, cantidad: 1 });
        //     productFil.push({ ...product, cantidad: 1 })
        //     cat.push(product.categori)
        // });
        // const kk = Object.keys(categoriasDinamicas).map((categoria, index) => {
        //     return categoria
        //          }

        // )
        // setCategoriasBan(kk)
        // setproductos(productFil)
        // setCategorias(categoriasDinamicas);
    }

    const onBlourMar = () => {

        setCategorias(null)

    }

    const addCantProduct = (e, item) => {

        setcantidad(cantidad + parseInt(e.target.value))
        console.log(parseInt(e.target.value))
        console.log(item)

    }

    const onBlourMarText = () => {
        console.log('kkk')
        if (query.length > 0) {
            filterProducts()
        } else {
            setData()
            setFilteredProducts(null)
        }
    }



    const filterProducts = () => {
        const filtered = productos.filter(product => {
            // Aquí puedes agregar tus criterios de filtro, por ejemplo:
            return product.name.toLowerCase().includes(query.toLowerCase());
        });
        setFilteredProducts(filtered);
        //organizeByCategory(filtered);
    };


    const filterP = (category) => {
        setloadingCat(true)
        const nuevoInventario = { [category]: categorias[category] };
        setCategorias(nuevoInventario)
        setTimeout(() => {
            setloadingCat(false)
        }, 300);



    }


    if (loading) {

        return (
            <HomeLayouts>
                <Loading />
            </HomeLayouts>
        )

    }


    return (
        <HomeLayouts>
            <Grid
                paddingY={2}

                sx={{ paddingX: { xs: 1, md: 6 }, marginTop: { md: 5 } }}
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}

            >
                <Grid
                    sx={{ display: { xs: 'none', md: 'flex' }, }}
                    item
                    xs={12}
                    md={4}
                >
                    <div>
                        <h1 className={styles.title}>Marketplace</h1>
                    </div>
                </Grid>
                <Grid

                    item
                    xs={12}
                    md={4}
                >
                    <SearchBarMarket
                        onBlur={onBlourMar}
                        onBlurText={onBlourMarText}
                        name="query"
                        query={query}
                        placeholder="Buscar en Cado..."
                        setQuery={setQuery}
                    />
                </Grid>
                <Grid

                    flexDirection={'row'}
                    justifyContent={'flex-end'}
                    sx={{ display: { xs: 'none', md: 'flex' }, }}
                    md={4}
                    item
                    xs={12}
                >
                    <BtnResume />
                </Grid>
            </Grid>
            {/* <Box sx={{ overflow: { xs: 'scroll', md: 'hidden' }, justifyContent: { xs: 'center' } }} display={'flex'} flexDirection={'row'} padding={2} marginTop={2} >
                <Chip sx={{ marginRight: 2, fontSize: 17 }} label="Productos" onClick={() => console.log('boton 2')} />
                <Chip sx={{ marginRight: 2, fontSize: 17 }} label="Proveedores" onClick={() => console.log('boton 2')} />

            </Box> */}
            <Box mt={2} mb={4} sx={{ overflow: { xs: 'scroll', md: 'hidden' }, justifyContent: { md: 'center' } }} display={'flex'} flexDirection={'row'} padding={2}  >
                <Chip style={{ color: catSelect === 'Todos' ? 'white' : 'black', backgroundColor: catSelect === 'Todos' ? 'black' : '#00000014' }} sx={{ marginRight: 2, fontSize: 17 }} label='Todos' onClick={() => { setData(), setCatSeklect('Todos') }} />
                {
                    categoriasBna != null ?
                        categoriasBna.map((categoria, index) => (

                            <Chip key={index} style={{ color: catSelect === categoria ? 'white' : 'black', backgroundColor: catSelect === categoria ? 'black' : '#00000014' }} sx={{ marginRight: 2, fontSize: 17 }} label={categoria} onClick={() => { filterP(categoria), setCatSeklect(categoria) }} />

                        ))
                        : null
                }
                {/* <Chip sx={{ marginRight: 2, fontSize: 17, backgroundColor: 'black', color: 'white' }} label={categoria} onClick={() => console.log('boton 2')} />
                <Chip sx={{ marginRight: 2, fontSize: 17 }} label="Frutas" onClick={() => console.log('boton 2')} />
                <Chip sx={{ marginRight: 2, fontSize: 17 }} label="Carnes" onClick={() => console.log('boton 2')} />
                <Chip sx={{ marginRight: 2, fontSize: 17 }} label="Bebidas" onClick={() => console.log('boton 2')} />
                <Chip sx={{ marginRight: 2, fontSize: 17 }} label="Verduras" onClick={() => console.log('boton 2')} />
                <Chip sx={{ marginRight: 2, fontSize: 17 }} label="Bebidas (Sin alcohol)" onClick={() => console.log('boton 2')} /> */}
            </Box>

            {loadingCat ?

                <Loading /> :
                <Box padding={0} backgroundColor='#f6f6f6' >
                    {

                        categorias != null ?
                            Object.keys(categorias).map((categoria, index) => (

                                <CarrouselComp
                                    productos={productos}
                                    add={add}
                                    dism={dism}
                                    cantidad={cantidad}
                                    addCantProduct={addCantProduct}
                                    key={index}
                                    categoria={categoria}
                                    categorias={categorias}
                                />

                            ))
                            : null
                    }

                </Box>
            }


            <Grid
                marginTop={2}
                sx={{ paddingX: { xs: 0, md: 6 } }}
                container
            >

                {
                    filteredProducts && filteredProducts.map(item => (

                        <CardProductMovil
                            key={item.id}
                            item={item}
                        />

                    ))
                }
            </Grid>
        </HomeLayouts>
    )
}




