import axios from '../lib/axios';


   

export const login = (data) => {
  return axios.post('/auth/login', data);
};

export const register = (data) => {
 
  return axios.post('/restaurants/registration', data);
};

export const verify = (data) => {
  return axios.post('/auth/verification', data);
};

export const requestPass = (data) => {
  return axios.post('/auth/password-recovery-request', data);
};

export const confirmPass = (data) => {
  return axios.post('/auth/password-recovery-confirmation', data);
};



export const logout = () => {
  return axios.post('/auth/logout');
};
