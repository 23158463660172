import { Box, Button, Card, CardActions, CardContent, CardMedia, Chip, Grid, IconButton, Link, TextField, Typography } from '@mui/material'
import Carousel from 'react-multi-carousel'
import RemoveIcon from '@mui/icons-material/Remove';
import imgr from '../../../assets/fill.png'
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import notImage from '../../../assets/product-default.png'
import { addProductCar } from '../../../redux/slices/marketPLace/marketPlaceSlice';
import { Contador } from './Contador';
import PopUp from '../../../components/PopUp';
import QuoteFlash from '../../../components/PopUp/components/Flash/QuoteFlash';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2
    }
};

export const CarrouselComp = ({ categorias, categoria, addCantProduct, cantidad, add, dism, productos }) => {



    const dispatch = useDispatch()
    const { productsCar } = useSelector((state) => state.cart)
    const [item, setitem] = useState(null)
    const [showModal, setShowModal] = useState(false)
    const addToCart = (item) => {

        dispatch(addProductCar(item))
    }


    useEffect(() => {

    }, [])


    const itemExist = (id) => {

        if (productsCar.some((item) => item.id === id)) {
            return true
        } else {
            return false
        }
    }


    return (
        <>
            <PopUp size={'large'} setShowModal={setShowModal} showModal={showModal}>
                <QuoteFlash
                    item={item}
                    setShowModal={setShowModal}
                />
            </PopUp>
            <Box boxShadow={1} backgroundColor='white' border={0.5} borderColor={'#EEEEEE'} borderLeft={0} borderRight={0} paddingBottom={1} marginBottom={1} >
                <Box key={categoria} sx={{ paddingX: { xs: 1.2, md: 20 } }} marginTop={5} display={'flex'} flexDirection={'row'} justifyContent={'space-between'} marginBottom={2} >
                    <Typography fontWeight={'bold'} fontSize={18} > {categoria} </Typography>
                    <Link href={`/marketplace/categoria/${categoria}`} color='#49A35B'  > Ver más </Link>
                </Box>
                <Box sx={{ paddingX: { md: 10 } }}>
                    <Carousel containerClass="carousel-container" showDots={true} responsive={responsive}>
                        {
                            productos.filter((product) => product.category === categoria).map((item, index) => (
                                <Card

                                    key={index}
                                    elevation={0} sx={{ marginRight: { xs: 2, md: 2 }, marginBottom: 5, maxWidth: { md: 250, xs: 250 }, width: { md: 250 } }}  >
                                    <CardMedia
                                        component="img"
                                        sx={{ width: { md: 240, xs: 240 } }} border={0.1} borderColor={'#e1e1e1'}
                                        image={item.image === null ? notImage : item.image}
                                        alt="Live from space album cover"
                                    />

                                    <Box sx={{ mt: -3, display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: 200, minHeight: 200, }}>
                                        <CardContent
                                            sx={{ padding: 0 }}
                                        >
                                            <Typography marginBottom={0.5} sx={{ fontSize: { xs: '0.8rem', md: '0.8rem' } }} color={'#72BFBA'} component="div">
                                                {`${item.supplier}`}
                                            </Typography>
                                            <Typography sx={{
                                                color: '#5c5c5c',
                                                fontWeight: 'bold',
                                                marginBottom: 0.5,
                                                fontSize: { xs: '1.2rem', md: '1.2rem' },
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                display: "-webkit-box",
                                                WebkitLineClamp: "2",
                                                WebkitBoxOrient: "vertical",
                                            }}>
                                                {`${item.name}`}
                                            </Typography>
                                            {/* {
                                            item.price !== null ?
                                                (<Typography sx={{ fontSize: { xs: '1.3rem', md: '1.3rem' } }} fontWeight={'bold'} component="div" >
                                                    {`$${item.price} x ${item.measurementUnit}`}
                                                </Typography>) : null
                                        } */}

                                        </CardContent>
                                        {/* <Contador
                                        add={add}
                                        dism={dism}
                                        cantidad={cantidad}
                                        addCantProduct={addCantProduct} 
                                        item={item}
                                    /> */}
                                        <CardActions sx={{ padding: 0, marginTop: 0 }} >
                                            <Button onClick={() => { setShowModal(true), setitem(item) }} sx={{ "&:hover": { backgroundColor: '#57575b' }, backgroundColor: '#32344B', borderRadius: 10, height: 36 }} variant='contained'  > <img src={imgr} alt="" /> </Button>
                                            <Button disabled={itemExist(item.id) ? true : false} onClick={() => addToCart(item)} sx={{ "&:hover": { backgroundColor: '#71b17c' }, backgroundColor: itemExist(item.id) ? '#fcfcfc' : '#49A35B', borderRadius: 10, marginLeft: 2 }} fullWidth variant='contained'  >{itemExist(item.id) ? <CheckIcon color='success' /> : ' Agregar'}</Button>
                                        </CardActions>
                                    </Box>
                                </Card>
                            ))
                        }
                    </Carousel>
                </Box>
            </Box>
        </>

    )
}
