import React, { useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import styles from './market.module.scss'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { Box, Grid, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import { getArrayOfErrors } from 'utils/errors';
import { CardResumeMarket } from './components/CardResumeMarket';
import { useSelector } from 'react-redux';
import emptySupplier from 'assets/emptySupplier.svg';
import CTA from '../../components/CTA';
import { flashQuote } from '../../api/marcketPlace';
import toast from 'react-hot-toast';



export const Resume = () => {
    const { restaurant, restaurantList, token, user } = useSelector((state) => state.auth)
    const { productsCar } = useSelector((state) => state.cart)
    const [isLoading, setIsloading] = useState(false)


    const navigate = useNavigate();
    console.log(productsCar)


    const sendData = async () => {
        if (productsCar.length > 0) {

            const dat = productsCar.map((product) => {

                return {
                    supplierId: product.supplierId,
                    anonymous: false,
                    note: "",
                    products: [
                        {
                            productId: product.id,
                            quantity: product.cantidad
                        }
                    ]
                }
            })

            console.log(dat)
            try {
                const resp = await flashQuote(token, user.id, restaurant, dat)

                console.log(resp)
                toast.success('Cotización enviada de manera correcta');
                setTimeout(() => {
                    setShowModal(false)
                    setIsloading(false)
                }, 500);
            } catch (error) {
                if (error.response) {
                    const errors = getArrayOfErrors(error.response.data);
                    for (let i = 0; i < errors.length; i++) {
                        toast.error(errors[i]);
                    }
                    setShowModal(false)
                    setIsloading(false)
                } else {
                    toast.error('Error al añadir proveedor');
                    setShowModal(false)
                    setIsloading(false)
                }


            }

        }


    }


    return (
        <HomeLayouts>
            <Grid

                sx={{ paddingX: { xs: 1, md: 15 }, marginTop: { md: 8 } }}
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'space-between'}

            >
                <Grid
                    marginBottom={3}
                    item
                    xs={12}
                    md={4}
                >
                    <Box display={'flex'} >
                        <Back className={styles.back} onClick={() => navigate('/marketplace')} />
                        <Typography fontWeight='bold' variant='h1' fontSize='28px' > Carrito </Typography>
                    </Box>
                </Grid>


            </Grid>

            {
                productsCar.length > 0 ?
                    <Grid

                        mb={2}
                        container
                        sx={{ paddingX: { sm: 2, md: 1, lg: 10, xs: 1, xl: 15 }, flexDirection: { md: 'row', xs: 'column-reverse' } }}
                    >
                        <Grid


                            sx={{ height: 600, overflow: 'auto', scrollbarColor: 'rgba(69, 69, 69, 0.19) transparent' }}
                            item
                            padding={2}
                            md={5}
                            xs={12}
                        >

                            {
                                productsCar && productsCar.map((item) => (


                                    <CardResumeMarket
                                        item={item}
                                    />

                                ))
                            }

                        </Grid>
                        <Grid
                            height={250}
                            ml={2}
                            borderRadius={3}
                            item
                            padding={2.3}
                            bgcolor={'#f7f7f7'}
                            md={6}
                            xs={12}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'space-between'}
                        >
                            <Box>
                                <Box display={'flex'} mb={2} >
                                    <Typography mr={0.5} fontWeight={'bold'} color={'#666666'} >Comercio:  </Typography>
                                    {restaurantList.find(
                                        (val) =>
                                            val.id === parseInt(restaurant),
                                    )?.name || '-'}

                                </Box>
                                <Box display={'flex'} mb={2} >
                                    <Typography mr={0.5} fontWeight={'bold'} color={'#666666'} >Dirección:  </Typography>
                                    {restaurantList.find(
                                        (val) =>
                                            val.id === parseInt(restaurant),
                                    )?.address || '-'}

                                </Box>
                            </Box>

                            <Box>
                                <CTA
                                    text='Solicitar cotización'
                                    onClick={sendData}
                                    isLoading={isLoading}
                                />
                            </Box>
                        </Grid>



                    </Grid>
                    :

                    <Grid padding={2} mt={5} >

                        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} >
                            <Typography mb={3} fontSize={20} fontWeight={'medium'} textAlign={'center'} >Aún no tienes procuctos. Agrega un producto a tu carrito.</Typography>
                            <img src={emptySupplier} alt="No suppliers" className={styles.img} />
                            <Box mt={5} >
                                <CTA
                                    size='small'
                                    isDisabled={false}
                                    text="Regresar "
                                    onClick={() => navigate('/marketplace')
                                    }
                                />
                            </Box>

                        </Box>

                    </Grid>

            }

        </HomeLayouts>
    )
}
