
import axios from "axios";

export const getProductsMarketPlace = async (token, limit, query, categoryId) => {


    const response = await axios.get( `${process.env.API_URL}/marketplace/products?limit=${limit}&query=${query}&categoryId${categoryId}`,
   
    {
     withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
  });

  if (response.status === 200) {
   
        return { ok:true,  data: response.data}
  }else{
   
    return { ok:false,  data:[] }

  }

   
  
  };


  export const flashQuote = async (token, userId, restaurantId, data )=>{

    

    const response = await axios.post( `${process.env.API_URL}/quotes/user/${userId}/restaurant/${restaurantId}`,
      data,
   
    {
     withCredentials:true,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Accept': '*/*',
        'content-type': 'application/json'
        
    }
      });

      if (response.status === 200) {
            return { ok:true,  data: response.data}
      }else{
        return { ok:false,  data:[] }
      }

  }