import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import { IoArrowBackOutline as Back } from '@react-icons/all-files/io5/IoArrowBackOutline';
import { Box, Grid, Typography } from '@mui/material'
import Loading from '../../components/Loading';
import styles from './market.module.scss'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { products } from './data';
import { CardProductMovil } from './components/CardProductMovil';
import SearchBarMarket from '../../components/SearchBar/SearchBarMarket';
import { BtnResume } from './components/BtnResume';
import { getProductsMarketPlace } from '../../api/marcketPlace';
import { useSelector } from 'react-redux';

export const CategoryMarket = () => {
  const { token } = useSelector((state) => state.auth)
  const { category } = useParams();
  const [loading, setloading] = useState(false)
  const [prod, setproductos] = useState(null)
  const [produFil, setproductosFil] = useState(null)
  const navigate = useNavigate();
  const [query, setQuery] = useState('');




  useEffect(() => {
    setProducts()
    console.log('de nuevo')
  }, [])


  const setProducts = async () => {
    setloading(true)
    const productFil = []
    const pro = await getProductsMarketPlace(token);

    console.log(pro)
    if (pro.ok) {
      pro.data.forEach(product => {
        productFil.push({ ...product, cantidad: 1 })
      });;
      const filtered = productFil.filter(product => {
        // Aquí puedes agregar tus criterios de filtro, por ejemplo:
        return product.category.toLowerCase().includes(category.toLowerCase());
      });
      setproductos(filtered)
      setproductosFil(filtered)
      setloading(false)
    } else {

      setproductos(null)
      setproductosFil(null)
      setloading(false)

    }
    // const productFil = []

    // products.forEach(product => {
    //   productFil.push({ ...product, cantidad: 1 })
    // });
    // const filtered = productFil.filter(product => {
    //   // Aquí puedes agregar tus criterios de filtro, por ejemplo:
    //   return product.categori.toLowerCase().includes(category.toLowerCase());
    // });

    // setTimeout(() => {
    //   setproductos(filtered)
    //   setproductosFil(filtered)
    //   setloading(false)
    // }, 1200);

  }


  const onBlourMar = () => {
    console.log('aqui-si')
    setproductos(null)

  }

  const onBlourMarText = () => {

    if (query.length > 0) {
      filterProducts()
    } else {
      setProducts()
    }
  }


  const filterProducts = () => {
    const filtered = produFil.filter(product => {
      // Aquí puedes agregar tus criterios de filtro, por ejemplo:
      return product.name.toLowerCase().includes(query.toLowerCase());
    });
    setproductos(filtered);
    //organizeByCategory(filtered);
  };

  return (

    <HomeLayouts>
      <Grid
        sx={{ paddingX: { xs: 1, md: 10 }, marginTop: { md: 5 } }}
        container
        alignItems={'center'}
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-between'}

      >
        <Grid
          marginBottom={3}
          item
          xs={6}
          md={4}
        >
          <Box display={'flex'} >
            <Back className={styles.back} onClick={() => navigate('/marketplace')} />
            <Typography fontWeight='bold' variant='h1' fontSize='28px' >{category}</Typography>
          </Box>
        </Grid>
        <Grid
          sx={{ display: { xs: 'flex', md: 'none' }, }}
          marginBottom={3}
        >
          <BtnResume
            text="Resumen"
            size="small"
            onClick={() => navigate('/marketplace/carrito')}
          />
        </Grid>
        <Grid

          item
          xs={12}
          md={4}
        >


          <SearchBarMarket
            onBlur={onBlourMar}
            onBlurText={onBlourMarText}
            name="query"
            query={query}
            placeholder="Buscar en Cado..."
            setQuery={setQuery}
          />
        </Grid>
        <Grid

          flexDirection={'row'}
          justifyContent={'flex-end'}
          sx={{ display: { xs: 'none', md: 'flex' }, }}
          md={4}
          item
          xs={12}
        >

          <BtnResume />

        </Grid>
      </Grid>

      {
        loading ?
          <Box height={500} width={'100%'} >
            <Loading />
          </Box> :
          <Grid
            marginTop={2}
            marginBottom={5}
            sx={{ paddingX: { xs: 0, md: 6 } }}
            container
          >

            {
              prod && prod.map((item, index) => (

                <CardProductMovil
                  key={index}
                  item={item}
                />

              ))
            }
          </Grid>
      }

    </HomeLayouts>
  )
}
