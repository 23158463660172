import React from 'react';
import styles from '../../../PopUp/pop-up.module.scss'
import CTARed from 'components/CTARed';
import { useState } from 'react';
import { getArrayOfErrors } from 'utils/errors';
import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material';
import CTA from '../../../CTA';
import { CheckBox } from '@mui/icons-material';
import { flashQuote } from '../../../../api/marcketPlace';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';


const QuoteFlash = ({
    item,
    setShowModal,
       onClick

}) => {
   
    const { restaurant, subscription, user, token } = useSelector((state) => state.auth)
    const [checked, setChecked] = React.useState(false);
    const [canti, setCant] = React.useState(null);
    const [isLoading, setIsloading] = useState(false)

    const handleSubmit = async (item) => {

        console.log('item', item)

        setIsloading(true)
        const data =[{
            supplierId: item.supplierId.toString() ,
            anonymous: checked,
            note: "",           
            products: [
              {
                productId: item.id,
                quantity: parseInt(canti) 
              }
            ]
          }]

        
          try {
            const resp = await flashQuote(token, user.id, restaurant, data  )

            console.log(resp)
            toast.success('Cotización enviada de manera correcta');
            setTimeout(() => {
                setShowModal(false)
                setIsloading(false)
            }, 500);
          } catch (error) {
            if (error.response) {
                const errors = getArrayOfErrors(error.response.data);
                for (let i = 0; i < errors.length; i++) {
                  toast.error(errors[i]);
                }
                setShowModal(false)
                setIsloading(false)
              } else {
                toast.error('Error al añadir proveedor');
                setShowModal(false)
                setIsloading(false)
              }

        
          }

       
          
       
    

    }
    const handleChange = (event) => {
        setChecked(event.target.checked);
      };


     

    return (
       <Box>
            <Typography fontSize={23} fontWeight={'bold'} >{ item.name } { item.measurementUnit }  </Typography>
            <Typography fontSize={13}  >{ item.category }</Typography>
            <Typography fontSize={13} color={'#72BFBA'} >{ item.supplier }</Typography>


            <Box mt={3} display={'flex'} flexDirection={'row'} alignItems={'center'}  >
            <TextField 
                onChange={( e)=>setCant(e.target.value)}
                value={!canti ? '' : canti}                
                placeholder='Cantidad a ordenar (Mensualmente)'
                size='small'
                type='number'
            />
            <Box ml={1} >
            <Typography> { item.measurementUnit } </Typography>
            </Box>
           
            </Box>
            <Box mt={3} >
                <FormControlLabel control={
                    <Checkbox 
                        color='success'
                        checked={checked}
                        onChange={handleChange}
                     />
                 } label="Deseas que tu cotizacion sea anonima?" />
            </Box>
           

            <Box mt={3} >
                <CTA
                    isDisabled={ canti === null || isLoading ? true :  false }
                    onClick={()=>handleSubmit(item) }
                    text='Cotizar'
                />
            </Box>

       </Box>
    );
};



export default QuoteFlash;
