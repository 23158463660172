import React, { useEffect, useState } from 'react'
import { HomeLayouts } from '../../components/layouts/HomeLayouts'
import Banner from '../../components/Banner/Banner'
import BannerFree from '../../components/Banner/BannerFree'
import styles from './myOrders.module.scss';
import { useSelector } from 'react-redux'
import { quoteByRestaurant } from '../../api/quotes';
import SearchBar from '../../components/SearchBar';
import Loading from '../../components/Loading';
import moment from 'moment';
import { Typography } from '@mui/material';

import DesktopQuotes from './components/DesktopQuotes';

export const Quotes = () => {
    const { restaurant, user, subscription, token } = useSelector((state) => state.auth)
    const [quotes, setquotes] = useState(null)
    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(false);
    const [params, setParams] = useState({
      page: 1,
      limit: 10,
      status: 'solicitada,aceptada',
      query: '',
    });

useEffect(() => {
  
    setQuotes()

}, [])

console.log(quotes)
const setQuotes = async ()=>{
    setLoading(true)
    const resp = await quoteByRestaurant(token, user.id, restaurant )
    console.log(resp)
    if (resp.ok) {
      setquotes(resp.data)
      setLoading(false)
    }else{
      setquotes([])
      setLoading(false)
    }
}


if (loading) return <HomeLayouts><Loading /></HomeLayouts>


  return (
    <HomeLayouts>
                 <div>
        {
          subscription && subscription.status === 'cancelada' ? <Banner />
            : null
        }
        {/* {
          orders_allowed?.status === true && orders_allowed.number <= 0 ? <BannerFree />
            : null
        } */}
        <div className={styles.content}>
          <div className={styles.header}>
            <div>
              <h1 className={styles.title}>Mis Cotizaciones</h1>
            </div>

           


          </div>
          
          <div className={styles.searchAndFilter}>
            <SearchBar
              query={params.query}
              setQuery={(val) => {
                setParams({ ...params, query: val });
              }}
              placeholder="Buscar en Mis Órdenes"
              name="query"
            />
            {/* <Filter /> */}
          </div>
         

          <div className={styles.statusTab}>
            
            <div
              className={`${styles.status} ${selectedTab === 0 && styles.active}`}
              onClick={() => {
                dispatch(clearParamsO())
                setpage(0)
                setSelectedTab(0);
                setParams({ ...params, page: 1, status: 'solicitada,aceptada' });
              }}
            >
              Solicitadas
            </div>
            <div
              className={`${styles.status} ${selectedTab === 1 && styles.active}`}
              onClick={async () => {
                dispatch(clearParamsO())
                setpage(0)
                setSelectedTab(1);
                setParams({ ...params, page: 1, status: 'entregada,cancelada' });
              }}
            >
              Completadas
            </div>
          </div> 
          <div className={styles.ordersContainer}>
            {loading ? (
              <Loading />
            ) : (
              quotes && quotes.map((order, i) => {
                console.log(order.id)
                return (
                  <div
                    key={`${i}_${order.id}`}
                    className={styles.orderWrapper}
                    onClick={(e) => {
                      navigateToOrder(e, order.code);
                    }}
                  >
                    <div className={styles.order}>
                      <div className={styles.orderInformation}>
                        <p className={styles.supplier}>{order.supplierId}</p>
                        <p className={styles.date}>
                          {moment(order.createdAt).format('DD-MM-YYYY')}
                        </p>
                        <label className={`${styles.label} ${styles[order.status]}`}>
                          {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                        </label>
                      </div>
                      <button
                        className={styles.reorder}
                        onClick={()=>console.log('')}
                        style={{
                          display: subscription?.status === 'cancelada' || user?.roles[0].name === 'Staff' ? 'none' : null,
                        }}
                      >
                        Reordenar
                      </button>
                    </div>
                   
                  </div>
                );
              })
            )}
          </div>     
          {
            !quotes ? null :
              <DesktopQuotes
                subscription={subscription}
                orders={quotes}
                params={params}
                setParams={setParams}
                reorder={()=>{}}
                navigateToOrder={()=>{}}
              />
          }  

        </div>
       
      </div>
            </HomeLayouts>
  )
}
