import React from 'react';
import { Routes, Route } from 'react-router-dom';


import NotFound from 'router/components/NotFound';

import subRoutes from './subRoutes';
import { CategoryMarket } from './CategoryMarket';
import { Marketplace } from './Marketplace';
import { Resume } from './Resume';

const MarketRoute = ({  }) => {

  return (
    <>
      <Routes>
        <Route path={subRoutes.market} element={<Marketplace  />} />
        <Route path={subRoutes.details} element={<CategoryMarket />} />
        <Route path={subRoutes.cart} element={<Resume />} />        
        {/* <Route path={subRoutes.recieve} element={<RecieveOrder />} />
        <Route path={subRoutes.confirmation} element={<OrderConfirmation />} />
        <Route path={subRoutes.newOrder} element={<NewOrder />} /> */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default MarketRoute;
